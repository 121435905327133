import axios from "axios";
import moment from "moment";

// Create a new instance of Axios
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Create a new instance of Axios
const dashboar_api = axios.create({
  baseURL: process.env.REACT_APP_DASHBOARD_BASE_URL,
});

// Create a new instance of Axios
const tounament_api = axios.create({
  baseURL: process.env.REACT_APP_TOURNAMENT_BASE_URL,
});

// Interceptor to include the bearer token in the request headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Assuming the token is stored in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor to include the bearer token in the request headers
dashboar_api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Assuming the token is stored in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor to include the bearer token in the request headers
tounament_api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Assuming the token is stored in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// API functions
export const getUserToken = async (token) => {
  // return true;
  try {
    const response = await dashboar_api.get(`/users/getuserbytoken`);

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getDailyAccounts = async (from = "", to = "") => {
  try {
    const response = await api.get(
      `/daily_active_users?from_date=${from}&to_date=${to}`
    );
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getMonthlyAccounts = async (from = "", to = "") => {
  try {
    const response = await api.get(
      `/mau_up_to_day_of_the_month?from_date=${from}&to_date=${to}`
    );
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getSalesIncome = async (from = "", to = "", matics = false, sogas = false) => {
  try {
    const response = await api.get(`/sales?from_date=${from}&to_date=${to}&matics=${matics}&soga=${sogas}`);
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getWorkersIncome = async (from = "", to = "", isMatics = false,isSogas = false,isCoin = false) => {
  try {
    const response = await api.get(
      `/worker/tasks?from_date=${from}&to_date=${to}&matics=${isMatics}&soga=${isSogas}&game_coins=${isCoin}      `
    );
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getWorkersSummary = async (from = "", to = "", isMatics = false,isSogas = false,isCoin = false) => {
  try {
    const response = await api.get(
      `/worker/tasks_summary?from_date=${from}&to_date=${to}&matics=${isMatics}&soga=${isSogas}&game_coins=${isCoin}   `
    );
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getSalesRankingByProduct = async (
  filterBy,
  from,
  to,
  owner,
  purchaser,
  category,
  status,
) => {
  try {
    const response = await api.get(
      `/tokenomics/marketplace_category_ranking?filter_by=${filterBy}&from_date=${from}&to_date=${to}&status=${status}&owner=${owner}&purchaser=${purchaser}&category=${category}`
    );
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getSalesRankingByCoins = async (
  filterBy,
  from,
  to,
  owner,
  purchaser,
  category,
  status,
) => {
  try {
    const response = await api.get(
      `/tokenomics/marketplace_coins_ranking?filter_by=${filterBy}&from_date=${from}&to_date=${to}&status=${status}&owner=${owner}&purchaser=${purchaser}&category=${category}`
    );
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getSalesRankingBySeller = async (
  filterBy,
  from,
  to,
  owner,
  purchaser,
  category,
  status,
) => {
  try {
    const response = await api.get(
      `/tokenomics/marketplace_sellers_ranking?filter_by=${filterBy}&from_date=${from}&to_date=${to}&status=${status}&owner=${owner}&purchaser=${purchaser}&category=${category}`
    );
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getAccountsTable = async (
  from = "",
  to = "",
  activeKols = false,
  referalCode = ""
) => {
  try {
    const response = await api.get(
      `/kol/kpi_list?from_date=${from}&to_date=${to}&status=${activeKols}&referal_code=${referalCode}`
    );
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getNewAccounts = async (from = "", to = "", directSales = false, rentals = false, marketplace = false,activeMatics = false,activeSogas = false,activeCoin = false) => {
  try {
    const newAcc = await api.get(
      `/new_accounts?from_date=${from}&to_date=${to}`
    );
    const totalAcc = await api.get(
      `/total_paying_accounts?from_date=${from}&to_date=${to}&marketplace=${marketplace}&direct_sales=${directSales}&rentals=${rentals}&matics=${activeMatics}&soga=${activeSogas}&game_coins=${activeCoin}`
    );
    const newPaying = await api.get(
      `/new_paying_accounts?from_date=${from}&to_date=${to}&marketplace=${marketplace}&direct_sales=${directSales}&rentals=${rentals}&matics=${activeMatics}&soga=${activeSogas}&game_coins=${activeCoin}`
    );
    const newAccPlayUser = await api.get(
      `/new_accounts_playing_users?from_date=${from}&to_date=${to}&marketplace=${marketplace}&direct_sales=${directSales}&rentals=${rentals}&matics=${activeMatics}&soga=${activeSogas}&game_coins=${activeCoin}`
    );

    let sortedNewPaying = newPaying.data.content.map((item) => {
      return {
        ...item,
        day: moment(item.day).format("MM/DD"),
        dayName: moment(item.day).format("dddd"),
      };
    });

    const handleNegative = (val1, val2) => {
      if (val1 - val2 < 0) return 0;
      else return val1 - val2;
    };
    let response = sortedNewPaying.map((item, index) => ({
      day: item.day,
      dayName: item.dayName,
      "Total paying accounts": Number(totalAcc.data.content[index].count),
      "New paying accounts": Number(item.count),
      "New Accounts wasted": handleNegative(
        Number(newAcc.data.content[index].count),
        Number(newAccPlayUser.data.content[index].count)
      ),
      "New Accounts Active Users": Number(
        newAccPlayUser.data.content[index].count
      ),
    }));

    return response;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getKPIsPeriod = async (from = "", to = "", directSales = false, rentals = false, marketplace = false,activeMatics = false,activeSogas = false,activeCoin = false) => {
  try {
    const response = await api.get(`/kpi_list?from_date=${from}&to_date=${to}&marketplace=${marketplace}&direct_sales=${directSales}&rentals=${rentals}&matics=${activeMatics}&soga=${activeSogas}&game_coins=${activeCoin}`);
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getNickNameSoga = async (name = "") => {
  try {
    const response = await api.get(`/kol/players?nickname=${name}`);
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getStreamMedia = async () => {
  try {
    const response = await api.get("/kol/media_channel");
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getKOLType = async () => {
  try {
    const response = await api.get("/kol/kol_type");
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const postMonthlyFee = async (query) => {
  try {
    const response = await api.post("/kol/monthly_fee", query);
    return response.result;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getMonthlyFee = async (id) => {
  try {
    const response = await api.get(`/kol/monthly_fee?id=${id}`);
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const removeMonthlyFee = async (id) => {
  try {
    const response = await api.delete(`/kol/monthly_fee?id=${id}`);
    return response.result;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getKOLAccountList = async (
  from = "",
  to = "",
  country = "",
  status = ""
) => {
  try {
    const response = await api.get(
      `/kol/accounts_list?from_date=${from}&to_date=${to}&country=${country}&status=${status}`
    );
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getKOLAccountId = async (id) => {
  try {
    const response = await api.get(`/kol/details?id=${id}`);
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const postNewKOLList = async (list) => {
  try {
    const response = await api.post("/kol/save", list);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const updateNewKOLList = async (list) => {
  try {
    const response = await api.put("/kol/update", list);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getSalesFunnel = async (from = "", to = "", referal) => {
  try {
    const response = await api.get(
      `/kpi/sales_funnel?referal_code=${referal}&from_date=${from}&to_date=${to}`
    );
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getSalesROI = async (from = "", to = "", referal) => {
  try {
    const response = await api.get(
      `/kpi/sales_roi?referal_code=${referal}&filter_from_date=${from}&filter_to_date=${to}`
    );
    return response.data.content;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getTokenomicsSales = async (
  from = "",
  to = "",
  page,
  itemPage,
  stats
) => {
  try {
    const response = await api.get(
      `/sales/track?from_date=${from}&to_date=${to}&page=${page}&item_page=${itemPage}&status=${stats}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const postMission = async (body) => {
  try {
    const response = await api.post("/missions_manager/create", body);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const updateMission = async (body) => {
  try {
    const response = await api.post("/missions_manager/update", body);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};


export const getMissionDetail = async (id) => {
  try {
    const response = await api.get(
      `/missions_manager/mission_details?id=${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getPlateformItems = async () => {
  try {
    const response = await api.get(
      `/missions_manager/platform_items`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getChallengesMission = async () => {
  try {
    const response = await api.get(
      `/missions_manager/all_challenges`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getMission = async ( page,itemPage,) => {
  try {
    const response = await api.get(
      `/missions_manager/all?page=${page}&item_page=${itemPage}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getNFTsSales = async (
  from = "",
  to = "",
  nftType,
  status,
  wallet,
  player,
  nftName,
  page,
  itemPage
) => {
  try {
    const response = await api.get(
      `/nfts_stats/get_details?from_date=${from}&to_date=${to}&nft_type=${nftType}&status=${status}&wallet=${wallet}&player=${player}&nft_name=${nftName}&page=${page}&item_page=${itemPage}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getCirculatingSupply = async (
  from = "",
  to = "",
  activeFrom = "",
  activeTo = "",
  nickname,
  referal_code,
  payingAccount
) => {
  try {
    let addPayingAccount = "";
    if (payingAccount) {
      addPayingAccount = "&paying_accounts=true";
    }
    const response = await api.get(
      `/circulant_supply?from_date=${from}&to_date=${to}&active_from=${activeFrom}&active_to=${activeTo}&nickname=${nickname}&referal_code=${referal_code}${addPayingAccount}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getEstimatedWorth = async (
  from = "",
  to = "",
  activeFrom = "",
  activeTo = "",
  nickname,
  referal_code,
  payingAccount
) => {
  try {
    let addPayingAccount = "";
    if (payingAccount) {
      addPayingAccount = "&paying_accounts=true";
    }
    const response = await api.get(
      `/circulant_supply/estimated_worth?from_date=${from}&to_date=${to}&active_from=${activeFrom}&active_to=${activeTo}&nickname=${nickname}&referal_code=${referal_code}${addPayingAccount}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getAccumulatedHeroes = async (
  from = "",
  to = "",
  activeFrom = "",
  activeTo = "",
  nickname,
  referal_code,
  payingAccount
) => {
  try {
    let addPayingAccount = "";
    if (payingAccount) {
      addPayingAccount = "&paying_accounts=true";
    }
    const response = await api.get(
      `/circulant_supply/accumulated_heroes?from_date=${from}&to_date=${to}&active_from=${activeFrom}&active_to=${activeTo}&nickname=${nickname}&referal_code=${referal_code}${addPayingAccount}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};
export const getCirculantPlayers = async (
  from = "",
  to = "",
  activeFrom = "",
  activeTo = "",
  nickname,
  referal_code,
  page,
  sortBy,
  sortDir,
  payingAccount
) => {
  try {
    let addPayingAccount = "";
    if (payingAccount) {
      addPayingAccount = "&paying_accounts=true";
    }
    const response = await api.get(
      `/circulant_supply/players?from_date=${from}&to_date=${to}&active_from=${activeFrom}&active_to=${activeTo}&nickname=${nickname}&referal_code=${referal_code}&page=${page}&sortby=${sortBy}&sortby_dir=${sortDir}${addPayingAccount}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getItemsMechanicsProformance = async (
  from = "",
  to = "",
  activeFrom = "",
  activeTo = "",
  nickname,
  referal_code,
  payingAccount
) => {
  try {
    let addPayingAccount = "";
    if (payingAccount) {
      addPayingAccount = "&paying_accounts=true";
    }
    const response = await api.get(
      `/circulant_supply/performance_mechanics?from_date=${from}&to_date=${to}&active_from=${activeFrom}&active_to=${activeTo}&nickname=${nickname}&referal_code=${referal_code}${addPayingAccount}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getAllKol = async () => {
  try {
    const response = await api.get(`/kol/all`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getTournamentList = async (id) => {
  try {
    const response = await tounament_api.get(`/tournaments/pvp_manager/list`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getTournamentSetup = async () => {
  try {
    const response = await tounament_api.get(
      `/tournaments/pvp_manager/get_setup`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const postNewTournament = async (
  tTemplate,
  bTemplate,
  server,
  title,
  regStart,
  regEnd,
  tournamentStart
  // tournamentEnd
) => {
  try {
    const response = await tounament_api.post(
      `/tournaments/pvp_manager/create?tournament_template=${tTemplate}&bracket_template=${bTemplate}&server_region=${server}&title=${title}&registration_starts=${regStart}&registration_ends=${regEnd}&tournament_starts=${tournamentStart}`
    );
    return response;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const postUpdateTournament = async (
  id,
  tTemplate,
  bTemplate,
  server,
  title,
  regStart,
  regEnd,
  tournamentStart,
  tournamentEnd
) => {
  try {
    const response = await tounament_api.post(
      `/tournaments/pvp_manager/update?id=${id}&tournament_template=${tTemplate}&bracket_template=${bTemplate}&server_region=${server}&title=${title}&registration_starts=${regStart}&registration_ends=${regEnd}&tournament_starts=${tournamentStart}&tournament_ends=${tournamentEnd}`
    );
    return response;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getTournamentDetails = async (id) => {
  try {
    const response = await tounament_api.get(
      `/tournaments/pvp_manager/details?id=${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const cancelTournament = async (id) => {
  try {
    const response = await tounament_api.post(
      `/tournaments/pvp_manager/stop?id=${id}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getRentalHeroes = async (
  filterBy,
  from,
  to,
  owner,
  rental,
  status = "",
  collection = "",
  rank = "",
  page,
  itemPage,
  sortBy,
  sortByDir
) => {
  try {
    const response = await api.get(
      `/rental/heroes?filter_by=${filterBy}&from_date=${from}&to_date=${to}&owner=${owner}&renter=${rental}&status=${status}&collection=${collection}&rank=${rank}&page=${page}&item_page=${itemPage}&sortby=${sortBy}&sortby_dir=${sortByDir} `
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getTokenomicSale = async (
  from,
  to,
  page,
  sortBy,
  sortByDir,
  nickname,
  itemPage,
  category,
  referalCode = "",
  country = ""
) => {
  try {
    const response = await api.get(
      `/tokenomics/sales?from_date=${from}&to_date=${to}&nickname=${nickname}&referal_code=${referalCode}&category=${category}&page=${page}&item_page=${itemPage}&sortby=${sortBy}&sortby_dir=${sortByDir}&country=${country} `
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getTokenomicMarketplace = async (
  filterBy,
  from,
  to,
  owner,
  purchaser,
  category,
  status,
  page,
  itemPage,
  sortBy,
  sortDir,
) => {
  try {
    const response = await api.get(
      `/tokenomics/marketplace?filter_by=${filterBy}&from_date=${from}&to_date=${to}&owner=${owner}&purchaser=${purchaser}&category=${category}&status=${status}&page=${page}&item_page=${itemPage}&sortby=${sortBy}&sortby_dir=${sortDir} `
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getTokeCountryRanking = async (
  from,
  to,
  nickname,
  referalCode = "",
  category,
  country = ""
) => {
  try {
    const response = await api.get(
      `/tokenomics/countries_ranking?from_date=${from}&to_date=${to}&nickname=${nickname}&referal_code=${referalCode}&category=${category}&country=${country}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export const getTokeProductRanking = async (
  from,
  to,
  nickname,
  referalCode = "",
  category,
  country = ""
) => {
  try {
    const response = await api.get(
      `/tokenomics/products_ranking?from_date=${from}&to_date=${to}&nickname=${nickname}&referal_code=${referalCode}&category=${category}&country=${country}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data");
  }
};

export default api;
